.register-container {
   width: 100%;
   height: calc(100vh - 83px);

   .container {
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .register-tab {
         width: 48%;
         background: #f6fafd;
         padding: 25px;
         border-radius: 15px;

         .tab-btns {
            width: 220px;
            display: flex;
            justify-content: space-evenly;
            flex-direction: row;
            background: #fff;
            margin: 0 auto 15px;
            gap: 10px;
            border-radius: 5px;
            padding: 0 5px;

            .tab-btn {
               width: auto;
               border: none;
               background: transparent;
               padding: 10px 0;
               flex: 1;
               text-align: center;
               border-radius: 0;
               border-bottom: 2px solid transparent;

               &.tab-active {
                  border-bottom: 2px solid #8061b3;
               }
            }
         }

         .tab-content {
            border: none;
            background: transparent;

            .tab-description {
               text-align: center;
               font-size: 14px;
               line-height: 26px;
               color: #000;
               font-weight: 500;
            }

            .tab-image {
               width: 100%;
               height: 380px;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
               }
            }
         }
      }

      .register-modal {
         width: 48%;
         min-width: 345px;
         padding: 20px 35px;

         .progress-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #000;
            font-weight: 500;
            margin: 0 0 10px;
            font-size: 20px;
            line-height: 1;

            span {
               font-size: 16px;
               color: #4c6280;
            }
         }

         .progress {
            width: 100%;
            height: 5px;
            margin: 15px 0 20px;

            .progress-bar {
               background-color: #8061b3;
            }
         }

         .new-form {
            .form-group {
               position: relative;

               .form-label {
                  margin: 0;
                  color: #000;
                  font-weight: 400;

                  span {
                     font-size: 12px;
                  }
               }

               .form-control {
                  border-radius: 0;
                  border: none;
                  border-bottom: 1px solid #e0e0e0;
                  padding: 0;
                  font-size: 16px;
               }

               .form-error {
                  margin: 0;
                  color: #eb0000;
                  font-size: 13px;
                  line-height: 20px;
                  font-weight: 500;
                  display: inline-block;
                  position: absolute;
                  bottom: -20px;
                  left: 0;
               }

               &:has(.form-error) {
                  margin-bottom: 1.5rem;
               }

               .toggle-pass {
                  position: absolute;
                  right: 0;
                  top: 45px;
                  border: none;
                  background: transparent;
                  color: #7f61b2;
                  font-size: 15px;
               }

               &.domain-group {
                  .pre-domain {
                     position: absolute;
                     right: 0;
                     top: 32px;
                     font-size: 16px;
                  }

                  .form-control {
                     padding-right: 115px;
                  }
               }
            }

            .remember-group {
               width: 100%;
               display: flex;
               align-items: center;
               justify-content: space-between;
               margin: 0 0 15px;

               .remember {
                  label {
                     margin: 0;
                     width: auto;
                     padding-left: 25px;
                  }
               }

               .forget-password {
                  border: none;
                  background: transparent;
                  color: #757575;
                  font-size: 14px;
               }
            }

            .invalid-data {
               text-align: center;
               color: #ab0000;
               font-size: 15px;
               font-weight: 500;
               padding: 3px;

               .close {
                  position: absolute;
                  right: 12px;
                  top: 9px;
                  opacity: 0.7;
                  text-shadow: none;
               }
            }

            .valid-data {
               text-align: center;
               color: #28623c;
               font-size: 15px;
               font-weight: 500;
               padding: 3px 30px;

               .sign-btn {
                  background: #4c9164;
                  color: #eefff4;
                  padding: 5px 10px;
                  border-radius: 3px;
                  font-size: 14px;
               }

               .close {
                  position: absolute;
                  right: 12px;
                  top: 9px;
                  opacity: 0.7;
                  text-shadow: none;
               }
            }

            .step-btns {
               width: 100%;
               display: flex;
               justify-content: space-between;
               align-items: center;
               margin: 25px 0 0;

               &:has(:only-child) {
                  justify-content: center;
               }

               .step-btn {
                  width: 145px;
                  background-color: #8061b3;
                  border-radius: 5px;
                  border: 1px solid;
                  font-size: 15px;
                  padding: 10px 25px;
                  transition: all 0.2s;

                  &.step-prev {
                     border-color: #e1e8f0;
                     background-color: #e1e8f0;
                     color: #000;
                  }

                  &.step-nxt {
                     border-color: #8061b3;
                     background-color: #8061b3;
                     color: #fff;

                     &:hover {
                        background-color: #541cb8;
                        border: 1px solid #541cb8;
                     }
                  }
               }
            }

            .reg-here {
               width: 100%;
               display: flex;
               justify-content: center;
               gap: 10px;
               font-size: 15px;
               color: #757575;
               margin: 10px 0;

               a {
                  color: #2d333e;
                  font-weight: 500;
               }
            }

            .login-through {
               width: 100%;
               display: flex;
               justify-content: space-evenly;
               margin: 20px 0 10px;

               button {
                  border: none;
                  background: #edf2f6;
                  padding: 10px 35px;
                  color: #4d4d4d;
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  font-size: 16px;
                  font-weight: 500;
                  gap: 10px;

                  img {
                     width: 24px;
                     height: 24px;
                  }
               }
            }
         }
      }
   }
}

.thank-register {
   width: 100%;
   background: #ffffff;
   padding: 50px;
   text-align: center;
   display: flex;
   flex-direction: column;
   align-items: center;

   span {
      width: 150px;
      height: 150px;
      margin: 0 0 15px;
      overflow: hidden;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
         transform: scale(2);
      }
   }

   .sign-btn {
      width: auto;
      background: #8061b3;
      color: #ffffff;
      padding: 2px 15px;
      border-radius: 3px;
      font-size: 16px;
      margin: 10px 0 0;
   }
}

@media only screen and (max-width: 767px) {
   .register-container .container {
      width: 100%;
      height: auto;
      flex-direction: column-reverse !important;
   }

   .register-container .container .register-modal,
   .register-container .container .register-tab {
      width: 100%;
   }
}